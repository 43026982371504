$red: #CC342D;

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  background: #f5f7fb;
}

.navbar-logo {
  width: 40px;
  height: 40px;
}

.navbar-nav .nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.text-red {
  color: $red;
}

.text-gray {
  color: #adb5bd;

  svg path {
    fill: #adb5bd;
  }
}

a.text-gray:hover {
  color: #ced4da;

  svg path {
    fill: #ced4da;
  }
}

.intro {
  background-color: rgba(204, 52, 45, 1);
  background-image: linear-gradient(0deg, rgba(204, 52, 45, 1) 0%, rgba(231, 101, 98, 1) 100%);
  padding: 7rem 0;
}

.home-title {
  line-height: 1;
}

.btn-intro-main {
  background: #ffffff;
  font-weight: 500;

  &:hover {
    background: #ffffff;
  }
}

.text-big {
  font-size: 1.2rem;
}

.text-huge {
  font-size: 1.5rem;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

footer {
  margin-top: auto;
}

.home-image {
  border: 1px solid #dee2e6;
  overflow: hidden;
  border-radius: 10px;
}

.bg-red {
  background: $red;
}

.icon-huge {
  font-size: 7rem;
}

.svg-icon {
  height: 22px;
  display: inline-block;
  vertical-align: text-top;
}
